import React, { useEffect, useState } from 'react';
import { graphql, useMutation, useQueryLoader } from 'react-relay/hooks';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ErrorBox } from '@sonika-se/ui-kit/components';
import SurveyResponse, { surveyResponseQuery } from './components/SurveyResponse';
export const SurveyResponsePage = () => {
    const [searchParams] = useSearchParams();
    const [surveyResponseProps, setSurveyResponseProps] = useState();
    const [error, setError] = useState(false);
    const [createSurveyResponse, createSurveyResponseLoading] = useMutation(graphql `
        mutation SurveyResponsePage_startSurveyResponseMutation($model: StartSurveyResponseRequestInput!) {
          surveyResponder_startSurveyResponse(model: $model) {
            responseEdge {
              node {
                id
              }
            }
          }
        }
      `);
    const [surveyResponseQueryRef, loadSurveyResponseQuery, disposeSurveyResponseQuery] = useQueryLoader(surveyResponseQuery);
    useEffect(() => {
        const collectorExternalId = searchParams.get('collectorExternalId');
        let responseExternalId = searchParams.get('responseExternalId');
        if (responseExternalId) {
            console.log('Found responseExternalId in search params');
        }
        else {
            console.log('No responseExternalId found in search params');
            const surveyResponseParams = JSON.parse(localStorage.getItem('surveyResponseParams'));
            responseExternalId = surveyResponseParams && surveyResponseParams[collectorExternalId];
            if (!responseExternalId) {
                console.log('No responseExternalId found in local storage - generating new');
                responseExternalId = uuidv4();
            }
            else {
                console.log('responseExternalId found in local storage');
            }
            // Store collectorExternalId and responseExternalId as key/value pairs in locale storage
            localStorage.setItem('surveyResponseParams', JSON.stringify(Object.assign(Object.assign({}, surveyResponseParams), { [collectorExternalId]: responseExternalId })));
        }
        createSurveyResponse({
            onCompleted: () => {
                loadSurveyResponseQuery({ collectorExternalId, responseExternalId }, { fetchPolicy: 'network-only' });
            },
            onError: (error) => {
                setError(true);
            },
            variables: {
                model: {
                    collectorExternalId,
                    externalResponseId: responseExternalId,
                },
            },
        });
    }, []);
    if (error) {
        return (<ErrorBox style={{ height: '100vh' }} type="page">
        Undersökningen kunde inte laddas. Försök igen eller kontakta ansvarig för undersökningen.
      </ErrorBox>);
    }
    return surveyResponseQueryRef && <SurveyResponse preloadedQuery={surveyResponseQueryRef}/>;
};
export default SurveyResponsePage;

import React from 'react';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            boxSizing: 'border-box',
            margin: '0 auto',
            maxWidth: pxToRem(768),
            padding: pxToRem(32),
            [`@media (max-width: ${theme.breakPoints.low})`]: {
                padding: pxToRem(16),
            },
        },
    };
});
export const Content = (props) => {
    const { children, className, style } = props;
    const classes = useStyles();
    return (<div className={[className, classes.root].join(' ')} style={style}>
      {children}
    </div>);
};
export default Content;

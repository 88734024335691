import React, { useMemo } from 'react';
import { Button, Flexbox, H1, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import Content from '../../../components/Layout/Content';
export const Intro = (props) => {
    const { onNext, surveyResponseRef } = props;
    const { firstUnansweredQuestion, survey } = useFragment(graphql `
      fragment Intro_surveyResponse on SurveyResponse {
        firstUnansweredQuestion {
          id
          locationPercentage
        }
        survey {
          options
          title
        }
      }
    `, surveyResponseRef);
    const { size: pageSize } = useViewport();
    const description = useMemo(() => {
        return survey.options && survey.options['description'] && survey.options['description'].split('\n');
    }, [survey]);
    return (<Content style={{
            marginTop: pageSize === 'large' ? '3rem' : '2rem',
        }}>
      <Flexbox direction="column" gap="medium">
        <H1 appearance={pageSize === 'large' ? 'h1' : 'h5'}>{survey.title}</H1>
        {description && (<Flexbox direction="column" gap="small">
            {description.map((text, index) => (<Text key={`description_${index}`} lineHeight="onehalf" size={pageSize === 'small' ? 'small' : 'medium'}>
                {text}
              </Text>))}
          </Flexbox>)}
        <Button display="block" onClick={() => onNext({ nextQuestionId: firstUnansweredQuestion === null || firstUnansweredQuestion === void 0 ? void 0 : firstUnansweredQuestion.id })} style={{ marginTop: '2rem' }}>
          {firstUnansweredQuestion.locationPercentage === 0 ? 'Starta undersökningen' : 'Fortsätt undersökningen'}
        </Button>
      </Flexbox>
    </Content>);
};
export default Intro;

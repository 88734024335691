import React from 'react';
import { Flexbox, Text } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        progress: {
            backgroundColor: '#FD544F',
            borderRadius: pxToRem(8),
            height: pxToRem(16),
            position: 'absolute',
            transition: 'left 0.25s ease-in',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: pxToRem(16),
        },
        step: {
            background: '#DEEFFF',
            height: pxToRem(8),
            flex: 1,
            '&:first-child': {
                borderRadius: `${pxToRem(4)} 0 0 ${pxToRem(4)}`,
            },
            '&:nth-last-child(2)': {
                borderRadius: `0 ${pxToRem(4)} ${pxToRem(4)} 0`,
            },
        },
        track: {
            display: 'flex',
            flex: 1,
            position: 'relative',
        },
    };
});
export const PercentageBar = (props) => {
    const { percentage } = props;
    const classes = useStyles();
    return (<Flexbox align="center" gap="medium">
      <div className={classes.track}>
        <div className={classes.step}/>
        <div className={classes.progress} style={{ left: `${percentage}%` }}/>
      </div>
      <Text>{Math.round(percentage)}%</Text>
    </Flexbox>);
};
export default PercentageBar;

import React, { useMemo } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { Button, Checkbox, Field, Fieldset, Flexbox, Form, H1, H3, Input, Label, RadioButton, Text, Textarea, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';
import { createUseStyles } from 'react-jss';
import Content from '../../..//components/Layout/Content';
const useStyles = createUseStyles((theme) => {
    const { shadow } = theme.helpers;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        content: {
            flex: 1,
        },
        footer: {
            boxShadow: shadow(2),
        },
    };
});
const FormActions = (props) => {
    const { disabled, onBack, style } = props;
    return (<Flexbox justify="space" style={Object.assign({ width: '100%' }, style)}>
      <Button appearance="outlined" color="primary" disabled={disabled} onClick={() => onBack()}>
        Tillbaka
      </Button>
      <Button disabled={disabled} form="surveyResponseForm" type="submit">
        Nästa
      </Button>
    </Flexbox>);
};
export const SurveyResponseStep = (props) => {
    var _a, _b, _c;
    const { currentQuestionId, responseId, disabled = false, onBack, onNext } = props;
    const { surveyResponder_surveyStep: data } = useLazyLoadQuery(graphql `
      query SurveyResponseStepQuery($responseId: ID!, $questionId: ID!) {
        surveyResponder_surveyStep(responseId: $responseId, questionId: $questionId) {
          previousQuestion {
            id
            locationPercentage
          }
          currentExchange {
            id
            response {
              separators: answeredQuestions(where: { type: { eq: SEPARATOR } }) {
                title
                sortOrder
              }
            }
            answer {
              ... on Node {
                id
              }
              ... on SurveyResponseMultipleChoiceAnswer {
                selectedAlternatives {
                  edges {
                    node {
                      alternativeId
                    }
                  }
                }
                text
              }
              ... on SurveyResponseSingleChoiceAnswer {
                selectedAlternativeId
                text
              }
              ... on SurveyResponseTextAnswer {
                text
              }
            }
            question {
              id
              alternatives(order: [{ sortOrder: ASC }]) {
                nodes {
                  ... on SurveyQuestionSingleChoiceAlternative {
                    id
                    text
                  }
                  ... on SurveyQuestionMultipleChoiceAlternative {
                    id
                    text
                  }
                }
              }
              notRequired
              options
              showTextAnswer
              survey {
                title
              }
              title
              type
              sortOrder
            }
          }
        }
      }
    `, {
        questionId: currentQuestionId,
        responseId: responseId,
    }, {
        fetchPolicy: 'network-only',
    });
    const { previousQuestion } = data;
    const { answer, question, response } = data.currentExchange;
    const survey = question.survey;
    const lastSeparatorToShow = useMemo(() => {
        if (response.separators.length === 0) {
            return null;
        }
        if (question.type === 'SEPARATOR') {
            return null;
        }
        const sorted = response.separators
            .filter((x) => x.sortOrder < question.sortOrder)
            .map((x) => x)
            .sort((x, y) => x.sortOrder - y.sortOrder);
        return sorted[sorted.length - 1];
    }, [response]);
    const classes = useStyles();
    const [setSurveyResponseAnswer, setSurveyResponseAnswerLoading] = useMutation(graphql `
        mutation SurveyResponseStep_setSurveyResponseAnswerMutation($model: SetSurveyResponseAnswerRequestInput!) {
          surveyResponder_setSurveyResponseAnswer(model: $model) {
            response {
              status
            }
            nextQuestion {
              id
              locationPercentage
            }
          }
        }
      `);
    const validationSchema = object({
        multipleChoice: question.type === 'MULTIPLE_CHOICE' && !question.notRequired
            ? array()
                .of(string())
                .test((answers) => answers.length > 0)
                .required()
            : array().of(string()).nullable().notRequired(),
        singleChoice: question.type === 'SINGLE_CHOICE' && !question.notRequired
            ? string().required()
            : string().nullable().notRequired(),
        text: question.type === 'TEXT' && !question.notRequired ? string().required() : string().nullable().notRequired(),
    });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            multipleChoice: ((_b = (_a = answer === null || answer === void 0 ? void 0 : answer.selectedAlternatives) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node: alternative }) => alternative.alternativeId)) || [],
            singleChoice: answer === null || answer === void 0 ? void 0 : answer.selectedAlternativeId,
            text: answer === null || answer === void 0 ? void 0 : answer.text,
        },
        resolver: yupResolver(validationSchema),
    });
    const { size: pageSize } = useViewport();
    return (<div className={classes.root}>
      <div className={classes.content}>
        <Content>
          <Text>{survey.title}</Text>
          <H3 appearance={pageSize === 'large' ? 'h3' : 'h6'}>{(_c = lastSeparatorToShow === null || lastSeparatorToShow === void 0 ? void 0 : lastSeparatorToShow.title) !== null && _c !== void 0 ? _c : <span>&nbsp;</span>}</H3>
        </Content>
        <Content>
          <Flexbox direction="column" gap="medium">
            <H1 appearance={pageSize === 'large' ? 'h1' : 'h5'}>{question.title}</H1>
            {question.options && question.options['description'] && <Text>{question.options['description']}</Text>}
            <Form id="surveyResponseForm" onSubmit={handleSubmit((formData) => {
            setSurveyResponseAnswer({
                onCompleted: ({ surveyResponder_setSurveyResponseAnswer: response }) => {
                    var _a, _b, _c;
                    onNext({
                        nextQuestionId: (_a = response.nextQuestion) === null || _a === void 0 ? void 0 : _a.id,
                        responseStatus: response.response.status,
                        questionLocationPercentage: (_c = (_b = response.nextQuestion) === null || _b === void 0 ? void 0 : _b.locationPercentage) !== null && _c !== void 0 ? _c : 100,
                    });
                },
                onError: (error) => {
                    console.log({ error });
                },
                variables: {
                    model: {
                        multipleChoiceSelectedAlternativeIds: formData.multipleChoice,
                        questionId: question.id,
                        responseId: responseId,
                        singleChoiceSelectedAlternativeId: formData.singleChoice,
                        textAnswer: formData.text,
                    },
                },
            });
        })}>
              {question.type === 'MULTIPLE_CHOICE' && (<Fieldset gap="medium">
                  <Controller control={control} name="multipleChoice" render={({ field }) => (<>
                        {question.alternatives.nodes.map((alternative) => {
                    var _a;
                    return (<Field direction="row-reverse" error={(_a = errors === null || errors === void 0 ? void 0 : errors.multipleChoice) === null || _a === void 0 ? void 0 : _a.message} label={alternative.text} key={alternative.id}>
                            <Checkbox defaultChecked={field.value.includes(alternative.id)} disabled={disabled || setSurveyResponseAnswerLoading} onChange={(event) => {
                            if (event.target.checked) {
                                field.onChange([...field.value, alternative.id]);
                            }
                            else {
                                field.onChange([...field.value].filter((id) => id !== alternative.id));
                            }
                        }}/>
                          </Field>);
                })}
                      </>)}/>
                </Fieldset>)}
              {question.type === 'SINGLE_CHOICE' && (<Fieldset gap="medium">
                  <Controller control={control} name="singleChoice" render={({ field }) => (<>
                        {question.alternatives.nodes.map((alternative) => {
                    var _a;
                    return (<Field direction="row-reverse" error={(_a = errors === null || errors === void 0 ? void 0 : errors.singleChoice) === null || _a === void 0 ? void 0 : _a.message} label={alternative.text} key={alternative.id}>
                            <RadioButton checked={field.value === alternative.id} disabled={disabled || setSurveyResponseAnswerLoading} onChange={() => {
                            field.onChange(alternative.id);
                        }} value={alternative.id}/>
                          </Field>);
                })}
                      </>)}/>
                </Fieldset>)}
              {(question.type === 'SINGLE_CHOICE' || question.type === 'MULTIPLE_CHOICE') && question.showTextAnswer && (<div style={{ marginTop: '1rem' }}>
                  <Flexbox direction="column" gap="xsmall">
                    <Label htmlFor="text" style={{ lineHeight: 'normal', padding: 0 }}>
                      Kommentar
                    </Label>
                    <Controller control={control} name="text" render={({ field }) => {
                var _a, _b;
                return (<Textarea defaultValue={field.value} disabled={disabled || setSurveyResponseAnswerLoading} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.text) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={field.onChange}/>);
            }}/>
                  </Flexbox>
                </div>)}
              {question.type === 'TEXT' && (<>
                  {question.options && question.options['subType'] === 'singleLine' ? (<Controller control={control} name="text" render={({ field }) => {
                    var _a, _b;
                    return (<Input defaultValue={field.value} disabled={disabled || setSurveyResponseAnswerLoading} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.text) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={field.onChange}/>);
                }}/>) : (<Controller control={control} name="text" render={({ field }) => {
                    var _a, _b;
                    return (<Textarea defaultValue={field.value} disabled={disabled || setSurveyResponseAnswerLoading} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.text) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={field.onChange} rows={5}/>);
                }}/>)}
                </>)}
              {pageSize === 'large' && (<FormActions disabled={disabled || setSurveyResponseAnswerLoading} onBack={() => {
                var _a;
                return onBack({
                    previousQuestionId: previousQuestion === null || previousQuestion === void 0 ? void 0 : previousQuestion.id,
                    questionLocationPercentage: (_a = previousQuestion === null || previousQuestion === void 0 ? void 0 : previousQuestion.locationPercentage) !== null && _a !== void 0 ? _a : 0,
                });
            }} style={{ marginTop: '5rem' }}/>)}
            </Form>
          </Flexbox>
        </Content>
      </div>
      {pageSize === 'small' && (<footer className={classes.footer}>
          <Content>
            <FormActions disabled={disabled || setSurveyResponseAnswerLoading} onBack={() => {
                var _a;
                return onBack({
                    previousQuestionId: previousQuestion === null || previousQuestion === void 0 ? void 0 : previousQuestion.id,
                    questionLocationPercentage: (_a = previousQuestion === null || previousQuestion === void 0 ? void 0 : previousQuestion.locationPercentage) !== null && _a !== void 0 ? _a : 0,
                });
            }}/>
          </Content>
        </footer>)}
    </div>);
};
export default SurveyResponseStep;

import React from 'react';
import { AppContextProvider } from '@sonika-se/ui-kit/components';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './App.css';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
export const App = () => {
    return (<AppContextProvider value={null}>
      <BrowserRouter>
        <div style={{ flex: 1 }}>
          <AppRoutes />
          <div id="drawerContainer"></div>
        </div>
      </BrowserRouter>
    </AppContextProvider>);
};
export default withAITracking(reactPlugin, App, null, 'flexfix');

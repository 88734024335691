/**
 * @generated SignedSource<<3db43dc3daffa1b3d8b38eb60e909762>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "questionId"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "responseId"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "questionId",
            "variableName": "questionId"
        },
        {
            "kind": "Variable",
            "name": "responseId",
            "variableName": "responseId"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "SurveyQuestion",
        "kind": "LinkedField",
        "name": "previousQuestion",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locationPercentage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v5 = [
        {
            "kind": "Literal",
            "name": "where",
            "value": {
                "type": {
                    "eq": "SEPARATOR"
                }
            }
        }
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sortOrder",
        "storageKey": null
    }, v8 = {
        "kind": "InlineFragment",
        "selections": [
            (v3 /*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alternativeId",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v11 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "selectedAlternativeId",
                "storageKey": null
            },
            (v10 /*: any*/)
        ],
        "type": "SurveyResponseSingleChoiceAnswer",
        "abstractKey": null
    }, v12 = {
        "kind": "InlineFragment",
        "selections": [
            (v10 /*: any*/)
        ],
        "type": "SurveyResponseTextAnswer",
        "abstractKey": null
    }, v13 = [
        {
            "kind": "Literal",
            "name": "order",
            "value": [
                {
                    "sortOrder": "ASC"
                }
            ]
        }
    ], v14 = [
        (v3 /*: any*/),
        (v10 /*: any*/)
    ], v15 = {
        "kind": "InlineFragment",
        "selections": (v14 /*: any*/),
        "type": "SurveyQuestionSingleChoiceAlternative",
        "abstractKey": null
    }, v16 = {
        "kind": "InlineFragment",
        "selections": (v14 /*: any*/),
        "type": "SurveyQuestionMultipleChoiceAlternative",
        "abstractKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notRequired",
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "options",
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showTextAnswer",
        "storageKey": null
    }, v20 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    }, v21 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "SurveyResponseStepQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "SurveyStepPayload",
                    "kind": "LinkedField",
                    "name": "surveyResponder_surveyStep",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SurveyResponseExchange",
                            "kind": "LinkedField",
                            "name": "currentExchange",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SurveyResponse",
                                    "kind": "LinkedField",
                                    "name": "response",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": "separators",
                                            "args": (v5 /*: any*/),
                                            "concreteType": "SurveyQuestion",
                                            "kind": "LinkedField",
                                            "name": "answeredQuestions",
                                            "plural": true,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": "answeredQuestions(where:{\"type\":{\"eq\":\"SEPARATOR\"}})"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "answer",
                                    "plural": false,
                                    "selections": [
                                        (v8 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SurveyQuestionMultipleChoiceAlternativeAnswerConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedAlternatives",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "SurveyQuestionMultipleChoiceAlternativeAnswerEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "SurveyQuestionMultipleChoiceAlternativeAnswer",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v9 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v10 /*: any*/)
                                            ],
                                            "type": "SurveyResponseMultipleChoiceAnswer",
                                            "abstractKey": null
                                        },
                                        (v11 /*: any*/),
                                        (v12 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SurveyQuestion",
                                    "kind": "LinkedField",
                                    "name": "question",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v13 /*: any*/),
                                            "concreteType": "SurveyQuestionAlternativeConnection",
                                            "kind": "LinkedField",
                                            "name": "alternatives",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "nodes",
                                                    "plural": true,
                                                    "selections": [
                                                        (v15 /*: any*/),
                                                        (v16 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "alternatives(order:[{\"sortOrder\":\"ASC\"}])"
                                        },
                                        (v17 /*: any*/),
                                        (v18 /*: any*/),
                                        (v19 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Survey",
                                            "kind": "LinkedField",
                                            "name": "survey",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/),
                                        (v20 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "SurveyResponseStepQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "SurveyStepPayload",
                    "kind": "LinkedField",
                    "name": "surveyResponder_surveyStep",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SurveyResponseExchange",
                            "kind": "LinkedField",
                            "name": "currentExchange",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SurveyResponse",
                                    "kind": "LinkedField",
                                    "name": "response",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": "separators",
                                            "args": (v5 /*: any*/),
                                            "concreteType": "SurveyQuestion",
                                            "kind": "LinkedField",
                                            "name": "answeredQuestions",
                                            "plural": true,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": "answeredQuestions(where:{\"type\":{\"eq\":\"SEPARATOR\"}})"
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "answer",
                                    "plural": false,
                                    "selections": [
                                        (v21 /*: any*/),
                                        (v8 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SurveyQuestionMultipleChoiceAlternativeAnswerConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedAlternatives",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "SurveyQuestionMultipleChoiceAlternativeAnswerEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "SurveyQuestionMultipleChoiceAlternativeAnswer",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v9 /*: any*/),
                                                                        (v3 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v10 /*: any*/)
                                            ],
                                            "type": "SurveyResponseMultipleChoiceAnswer",
                                            "abstractKey": null
                                        },
                                        (v11 /*: any*/),
                                        (v12 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SurveyQuestion",
                                    "kind": "LinkedField",
                                    "name": "question",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v13 /*: any*/),
                                            "concreteType": "SurveyQuestionAlternativeConnection",
                                            "kind": "LinkedField",
                                            "name": "alternatives",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "nodes",
                                                    "plural": true,
                                                    "selections": [
                                                        (v21 /*: any*/),
                                                        (v15 /*: any*/),
                                                        (v16 /*: any*/),
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "alternatives(order:[{\"sortOrder\":\"ASC\"}])"
                                        },
                                        (v17 /*: any*/),
                                        (v18 /*: any*/),
                                        (v19 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Survey",
                                            "kind": "LinkedField",
                                            "name": "survey",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/),
                                        (v20 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "259a96f91f983e431b31743c621355bf",
            "id": null,
            "metadata": {},
            "name": "SurveyResponseStepQuery",
            "operationKind": "query",
            "text": "query SurveyResponseStepQuery(\n  $responseId: ID!\n  $questionId: ID!\n) {\n  surveyResponder_surveyStep(responseId: $responseId, questionId: $questionId) {\n    previousQuestion {\n      id\n      locationPercentage\n    }\n    currentExchange {\n      id\n      response {\n        separators: answeredQuestions(where: {type: {eq: SEPARATOR}}) {\n          title\n          sortOrder\n          id\n        }\n        id\n      }\n      answer {\n        __typename\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n        ... on SurveyResponseMultipleChoiceAnswer {\n          selectedAlternatives {\n            edges {\n              node {\n                alternativeId\n                id\n              }\n            }\n          }\n          text\n        }\n        ... on SurveyResponseSingleChoiceAnswer {\n          selectedAlternativeId\n          text\n        }\n        ... on SurveyResponseTextAnswer {\n          text\n        }\n      }\n      question {\n        id\n        alternatives(order: [{sortOrder: ASC}]) {\n          nodes {\n            __typename\n            ... on SurveyQuestionSingleChoiceAlternative {\n              id\n              text\n            }\n            ... on SurveyQuestionMultipleChoiceAlternative {\n              id\n              text\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n        }\n        notRequired\n        options\n        showTextAnswer\n        survey {\n          title\n          id\n        }\n        title\n        type\n        sortOrder\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "b403bd480ccc7edb351866eeded36e2d";
export default node;

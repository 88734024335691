/**
 * @generated SignedSource<<a4fe301e4005c9915b027160bd4db61a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Intro_surveyResponse",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "SurveyQuestion",
            "kind": "LinkedField",
            "name": "firstUnansweredQuestion",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locationPercentage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Survey",
            "kind": "LinkedField",
            "name": "survey",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "options",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "SurveyResponse",
    "abstractKey": null
};
node.hash = "7a44f50cdd03d5ca80e02e450becb12c";
export default node;

import React from 'react';
import { useRoutes } from 'react-router-dom';
import SurveyResponsePage from './pages/SurveyResponsePage/SurveyResponsePage';
const AppRoutes = () => {
    return useRoutes([
        {
            path: '/respond',
            element: <SurveyResponsePage />,
        },
    ]);
};
export default AppRoutes;

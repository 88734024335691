import React from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import logo from '../../assets/sonika-s-icon.svg';
const useStyles = createUseStyles((theme) => {
    const { pxToRem, shadow } = theme.helpers;
    return {
        content: {
            flex: 1,
            overflow: 'auto',
        },
        footer: {
            backgroundColor: '#FFFFFF',
            boxShadow: shadow(2),
            flex: 0,
        },
        header: {
            backgroundColor: '#FFFFFF',
            boxShadow: shadow(2),
            flex: 0,
            padding: `${pxToRem(10)} ${pxToRem(14)}`,
        },
        headerLogo: {
            maxHeight: pxToRem(40),
            maxWidth: pxToRem(46),
        },
        root: {
            background: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
    };
});
export const Layout = (props) => {
    const { children, className, style } = props;
    const classes = useStyles();
    return (<div className={[className, classes.root].join(' ')} style={style}>
      {children}
    </div>);
};
export const LayoutContent = (props) => {
    const { children, className, style } = props;
    const classes = useStyles();
    return (<div className={[className, classes.content].join(' ')} style={style}>
      {children}
    </div>);
};
export const LayoutHeader = (props) => {
    const { children, className, style, logoUrl } = props;
    const classes = useStyles();
    return (<header className={[className, classes.header].join(' ')} style={style}>
      <Flexbox align="center" gap="medium">
        <img className={classes.headerLogo} src={logoUrl !== null && logoUrl !== void 0 ? logoUrl : logo}/>
        <div style={{ flex: 1 }}>{children}</div>
      </Flexbox>
    </header>);
};
export const LayoutFooter = (props) => {
    const { children, className, style } = props;
    const classes = useStyles();
    return (<footer className={[className, classes.footer].join(' ')} style={style}>
      {children}
    </footer>);
};
export default Layout;

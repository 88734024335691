import React, { Suspense, useState } from 'react';
import { graphql, usePreloadedQuery } from 'react-relay/hooks';
import { Flexbox, H1 } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import Intro from './Intro';
import Layout, { LayoutContent, LayoutHeader } from '../../../components/Layout/Layout';
import PercentageBar from './PercentageBar';
import SurveyResponseStep from './SurveyResponseStep';
export const surveyResponseQuery = graphql `
  query SurveyResponseQuery($collectorExternalId: Uuid!, $responseExternalId: String!) {
    surveyResponder_surveyResponse(collectorExternalId: $collectorExternalId, responseExternalId: $responseExternalId) {
      id
      status
      firstUnansweredQuestion {
        locationPercentage
      }
      survey {
        id
        title
        logo {
          url(queryTransform: "maxheight=46&maxwidth=40")
        }
      }
      ...Intro_surveyResponse
    }
  }
`;
export const SurveyResponse = (props) => {
    var _a, _b;
    const { preloadedQuery } = props;
    const data = usePreloadedQuery(surveyResponseQuery, preloadedQuery);
    const { firstUnansweredQuestion, id: responseId } = data.surveyResponder_surveyResponse;
    const [state, setState] = useState(data.surveyResponder_surveyResponse.status === 'FINISHED' ? 'end' : 'start');
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [questionLocationPercentage, setQuestionLocationPercentage] = useState((_a = firstUnansweredQuestion === null || firstUnansweredQuestion === void 0 ? void 0 : firstUnansweredQuestion.locationPercentage) !== null && _a !== void 0 ? _a : 100);
    const { size: pageSize } = useViewport();
    return (<Layout>
      <LayoutHeader logoUrl={(_b = data.surveyResponder_surveyResponse.survey.logo) === null || _b === void 0 ? void 0 : _b.url}>
        <PercentageBar percentage={questionLocationPercentage}/>
      </LayoutHeader>
      <LayoutContent style={{ backgroundColor: pageSize === 'small' ? 'transparent' : null }}>
        {state === 'start' && (<Intro onNext={(model) => {
                var _a;
                setState('question');
                setQuestionLocationPercentage((_a = firstUnansweredQuestion === null || firstUnansweredQuestion === void 0 ? void 0 : firstUnansweredQuestion.locationPercentage) !== null && _a !== void 0 ? _a : 0);
                setCurrentQuestionId(model.nextQuestionId);
            }} surveyResponseRef={data.surveyResponder_surveyResponse}/>)}
        {state === 'question' && currentQuestionId && (<Suspense fallback={<div />}>
            <SurveyResponseStep responseId={responseId} currentQuestionId={currentQuestionId} key={currentQuestionId} onBack={(model) => {
                setQuestionLocationPercentage(model.questionLocationPercentage);
                if (!model.previousQuestionId) {
                    setState('start');
                }
                else {
                    setCurrentQuestionId(model.previousQuestionId);
                }
            }} onNext={(response) => {
                setQuestionLocationPercentage(response.questionLocationPercentage);
                if (response.responseStatus === 'FINISHED') {
                    setState('end');
                }
                else {
                    setCurrentQuestionId(response.nextQuestionId);
                }
            }}/>
          </Suspense>)}
        {state === 'end' && (<Flexbox align="center" justify="center" style={{ height: '100%' }}>
            <H1 appearance={pageSize === 'large' ? 'h1' : 'h5'}>Tack för din medverkan!</H1>
          </Flexbox>)}
      </LayoutContent>
    </Layout>);
};
export default SurveyResponse;
